<template>
  <div class="modal-body">
    <div class="modal-body__close" @click="$emit('close')">
      <img src="@/assets/images/icons/x.svg" alt="close">
    </div>

    <h3 class="modal-body__title">Сохранено</h3>
    <p class="modal-body__description">Ваши настройки успешно сохранены</p>

    <VButton label="Хорошо" clickEffect class="modal-body__btn" @click="$emit('close')"/>
  </div>
</template>

<script setup></script>

<style scoped lang="scss">
.modal-body {
  position: relative;
  padding: 40px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__title {
    font-size: 20px;
    font-weight: 600;
  }

  &__description {
    margin-top: 8px;
    font-size: 16px;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__btn {
    width: 100%;
    margin-top: 24px;
  }
}
</style>
