import api from "./api";

const settings = {
    getSettings() {
        return api.get(`/settings`);
    },  
    postSettings(body) {
        return api.post(`/settings`, body);
    },  
};

export default settings;
